<template lang='pug'>
aside.excursion
  .excursion__cont.cont
    template(v-if="withDegustation")
      h1.excursion__caption.caption Экскурсии с дегустацией
      p.excursion__lid.text-big
        | Вы услышите легенды и необычные истории о шоколаде, а также сможете продегустировать все виды шоколада, которые мы используем для создания нашей продукции.
        br
        | Групповые и индивидуальные, детские и взрослые группы.
      h2.excursion__title.excursion__title_price.title Стоимость и длительность экскурсии с дегустацией
      .excursion__wrapper
        .excursion__group
          span.excursion__note.text-big Для гостей
          span.excursion__text
            | до 3 человек включительно — 3600&nbsp;₽
          span.excursion__text
            | от 4 до 15 человек — 1000&nbsp;₽/чел.
          span.excursion__note.text-big Для групп
          span.excursion__text
            | от 15 человек — 750&nbsp;₽/чел.
          span.excursion__duration
            | Продолжительность 45 минут, также вы можете
            |
            router-link.excursion__link(:to="{ name: 'Degustations' }") заказать дегустацию без экскурсии
    template(v-else)
      h1.excursion__caption.caption Экскурсии
      p.excursion__lid.text-big
        | Если хотите провести время у нас не только вкусно, но и полезно, тогда вам просто необходимо посетить
        | увлекательную экскурсию! Групповые и индивидуальные, детские и взрослые группы
      h2.excursion__title.title
        | Что вас ждет?
      p.excursion__text-big.text-big
        | Конечно, можно и самим осмотреть Музей с экспонатами выборгских достопримечательностей, но только
        | Хранительница музея расскажет вам:
      ul.excursion__list
        li.excursion__item.text-big историю появления шоколада
        li.excursion__item.text-big из чего же состоит настоящий шоколад
        li.excursion__item.text-big
          | сколько какао-бобов необходимо для изготовления одной шоколадной&nbsp;плитки
        li.excursion__item.text-big какие бывают виды и как правильно хранить шоколад
        li.excursion__item.text-big как отличить настоящий шоколад от подделки!
      h2.excursion__title.excursion__title_price.title Стоимость и длительность экскурсии
      .excursion__wrapper
        .excursion__group
          span.excursion__note.text-big Для гостей
          span.excursion__text
            | до 3 человек включительно — 2100&nbsp;₽
          span.excursion__text
            | от 4 до 15 человек — 500&nbsp;₽/чел.
          span.excursion__note.text-big Для групп
          span.excursion__text
            | от 15 человек — 350&nbsp;₽/чел.
          span.excursion__duration
            | Продолжительность 30 минут, также вы можете
            |
            router-link.excursion__link(:to="{ name: 'ExcursionWithDegustation' }") заказать экскурсию с дегустацией
    event-contact(:title="'Предварительная запись обязательна'", :text="'Узнайте о новых мероприятиях и запишитесь по телефону:'")
    event-gallery(
      :images="images",
      headline="Экскурсия в Музее «Шоколадный Выборг»",
      text="Посетители услышали легенды и необычные истории о шоколаде, а также продегустировали различные виды шоколада")
</template>

<script>
import '@/assets/styles/components/excursion.sass'
import EventContact from '../EventContact'
import EventGallery from '../EventGallery'

export default {
  name: 'TheExcursion',
  components: { EventGallery, EventContact },
  data () {
    return {
      images: [
        { path: '/images/photo-gallery/photo-gallery-15', alt: 'Групповые экскурсии в Музее «Шоколадный Выборг»', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-16', alt: 'Внутренняя экспозиция Музея «Шоколадный Выборг» во время экскурсии', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-17', alt: 'Дегустация шоколада на экскурсии в Музее «Шоколадный Выборг»', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-18', alt: 'Экскурсия и дегустация с возможностью приобрести шоколад', mod: 'small' },
        { path: '/images/photo-gallery/photo-gallery-19', alt: 'Экскурсанты в Музее «Шоколадный Выборг»', mod: 'small' }
      ]
    }
  },
  props: {
    withDegustation: {
      type: Boolean,
      default: false
    }
  }
}
</script>
